import styles from "./ProgressBar.module.scss"

function ProgressBar({ percentage }) {
  return (
    <div aria-label={`Maîtrise ${percentage}%`} className={styles.container}>
        <div className={styles.progress + " " + styles[`progress${percentage}`]}></div>
    </div>
  )
}

export default ProgressBar
