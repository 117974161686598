import work1 from "../../assets/images/booki.webp"
import work2 from "../../assets/images/sophie bluel.webp"
import work3 from "../../assets/images/kasa.webp"
import work4 from "../../assets/images/vieuxgrimoire.webp"
import work5 from "../../assets/images/osteodevito.webp"
import styles from "./Works.module.scss"
import { useIsVisible } from "../../functions/useIsVisible"

function Works() {
    const animation = useIsVisible("portfolio")

    return (
        <section id="portfolio" className={styles.section}>
            <div className={styles.angle}></div>
            <div animation={animation} className={styles.section__bloc}>
                <div className={styles.section__title}>
                    <h2>Portfolio <span>Projets</span></h2>
                    <div className={styles.section__titleLine}></div>
                </div>
                <div animation={animation} className={styles.section__container}>
                    <div tabIndex="0" className={styles.section__containerWork}>
                        <img src={work1} alt="Booki" />
                        <div>
                            <div>
                                <p>Projet <em>OpenClassrooms</em>.</p>
                                <p><em>Booki</em> souhaite développer un site Internet qui permette aux usagers de trouver des hébergements et des activités dans la ville de leur choix.</p>
                                <p>Je suis chargée d'<em>intégrer l'interface du site</em> avec du code HTML et CSS.</p>
                            </div>
                            <div className={styles.section__containerWorkFlex}>
                                <a rel="noreferrer" target="_blank" href="https://github.com/ClaireDV67/OC-Projet2">
                                    <span className="visually-hidden">Github</span>
                                    <i className="fa-brands fa-github fa-3x"></i>
                                </a>
                                <a rel="noreferrer" target="_blank" href="https://clairedv67.github.io/OC-Projet2/">
                                    <span className="visually-hidden">Site en ligne</span>
                                    <i className="fa-solid fa-link fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div tabIndex="0" className={styles.section__containerWork}>
                        <img src={work2} alt="Sophie Bluel" />
                        <div>
                            <div>
                                <p>Projet professionnalisant <em>OpenClassrooms</em>.</p>
                                <p>Création du <em>site portfolio</em> d'une architecte d'intérieur. </p>
                                <p>J'ai pour mission de <em>rendre le site dynamique</em> grâce à JavaScript.</p>
                            </div>
                            <div className={styles.section__containerWorkFlex}>
                                <a rel="noreferrer" target="_blank" href="https://github.com/ClaireDV67/OC-Projet3">
                                    <span className="visually-hidden">Github</span>
                                    <i className="fa-brands fa-github fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div tabIndex="0" className={styles.section__containerWork}>
                        <img src={work3} alt="Kasa" />
                        <div>
                            <div>
                                <p>Projet <em>OpenClassrooms</em>.</p>
                                <p><em>Kasa</em>, leader de la location d'appartements entre particuliers, souhaite développer sa nouvelle <em>plateforme web</em>.</p>
                                <p>Je suis chargée de la partie <em>front-end</em>, en React.</p>
                            </div>
                            <div className={styles.section__containerWorkFlex}>
                                <a rel="noreferrer" target="_blank" href="https://github.com/ClaireDV67/OC-Projet6">
                                    <span className="visually-hidden">Github</span>
                                    <i className="fa-brands fa-github fa-3x"></i>
                                </a>
                                <a rel="noreferrer" target="_blank" href="https://clairedv67.github.io/OC-Projet6/">
                                    <span className="visually-hidden">Site en ligne</span>
                                    <i className="fa-solid fa-link fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div tabIndex="0" className={styles.section__containerWork}>
                        <img src={work4} alt="Mon vieux grimoire" />
                        <div>
                            <div>
                                <p>Projet professionnalisant <em>OpenClassrooms</em>.</p>
                                <p>Une petite chaîne de librairies souhaite ouvrir un <em>site de référencement et de notation</em> de livres.</p>
                                <p>Je suis chargée du <em>back-end</em>, avec NodeJS et Express.</p>
                            </div>
                            <div className={styles.section__containerWorkFlex}>
                                <a rel="noreferrer" target="_blank" href="https://github.com/ClaireDV67/OC-Projet7">
                                    <span className="visually-hidden">Github</span>
                                    <i className="fa-brands fa-github fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div tabIndex="0" className={styles.section__containerWork}>
                        <img src={work5} alt="Ostéopathie" />
                        <div>
                            <div>
                                <p>Site d'une <em>vétérinaire ostéopathe et homéopathe</em> à domicile dans le secteur de Rennes.</p>
                                <p><em>Refonte totale</em> du site.</p>
                            </div>
                            <div className={styles.section__containerWorkFlex}>
                                <a rel="noreferrer" target="_blank" href="https://www.osteopathie-bdevito.fr/">
                                    <span className="visually-hidden">Site en ligne</span>
                                    <i className="fa-solid fa-link fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section__containerWork}>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Works
