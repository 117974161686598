import { useIsVisible } from "../../functions/useIsVisible"
import styles from "./Introduction.module.scss"

function Introduction() {
    const animation = useIsVisible("introduction")

    return (
        <section id="introduction" className={styles.section}>
            <div className={styles.angle}></div>
            <div animation={animation} className={styles.section__bloc}>
                <h2>Présentation <span>Portrait</span></h2>
                <div className={styles.section__line}></div>
                <p>Je suis passionnée par le <em>développement web</em> depuis quelques années, mais je ne travaille pas dans ce domaine.</p>
                <p>Souhaitant valider mes connaissances, et de personnalité autonome, j'ai décidé en 2023 de me former en distanciel grâce à OpenClassrooms. Je suis désormais l'heureuse titulaire d'un <em>titre RNCP de niveau V</em> en tant que développeur web.</p>
                <p>Je poursuis aujourd'hui ma passion en tant que <em>freelance</em>, en cumul de mon activité principale. Je code par plaisir, je suis donc ouverte à toute opportunité qui correspondrait à mes compétences.</p>
                <p>N'hésitez pas à me <em><a href="#contact">contacter</a></em>, je serai ravie de pouvoir vous aider !</p>
            </div>
        </section>
    )
}

export default Introduction
