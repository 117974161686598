import styles from "./Loader.module.scss"

function Loader() {
    return (
        <div className={styles.loader}>
            <div className={styles.loader__title}>Claire De Vito</div>
            <div className={styles.loader__line}></div>
            <div className={styles.loader__bubble1 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble2 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble3 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble4 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble5 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble6 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble7 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble8 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble9 + " " + styles.loader__bubble}></div>
            <div className={styles.loader__bubble10 + " " + styles.loader__bubble}></div>
        </div>
    )
}

export default Loader