import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"

export function useIsVisible(id) {
    const [animation, setAnimation] = useState(0)
    const scrollPosRef = useRef(0)

    // Fonction animant la section visible uniquement lors du scroll vers le bas
    useEffect(() => {
        function handleScroll() {
            const sectionElement = document.getElementById(id)
            if (window.scrollY > scrollPosRef.current) {
                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect()
                    const isVisibleAnimation = rect.top + 200 <= window.innerHeight && rect.bottom >= 10
                    isVisibleAnimation && setAnimation(1)
                }
            }
                
            scrollPosRef.current = window.scrollY
        }

        window.addEventListener("scroll", handleScroll)
        handleScroll() // Vérifie la visibilité initiale lors du montage du composant

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    return animation
}