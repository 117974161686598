import { HashRouter } from "react-router-dom"
import { useState, useEffect } from "react"
import Header from "../../sections/Header/Header"
import Introduction from "../../sections/Introduction/Introduction"
import Skills from "../../sections/Skills/Skills"
import Works from "../../sections/Works/Works"
import Contact from "../../sections/Contact/Contact"
import Footer from "../Footer/Footer"
import Loader from "../Loader/Loader"

function App() {
  const [isLoading, setIsLoading] = useState(true)

  // Simuler un chargement de 3 secondes
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 4000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <HashRouter>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <main>
            <Introduction />
            <Skills />
            <Works />
            <Contact />
          </main>
          <Footer />
        </>
      )}
    </HashRouter>
  )
}

export default App