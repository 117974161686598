import { useRef } from "react"
import emailjs from "@emailjs/browser"
import styles from "./Contact.module.scss"
import { useIsVisible } from "../../functions/useIsVisible"
import cvDoc from "../../assets/documents/CVdevitoclaire.pdf"


function Contact() {
    const animation = useIsVisible("contact")

    // Envoi d'un e-mail via le formulaire de contact avec EmailJS
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm("service_h7c4srd", "template_39k0xxn", form.current, "8QfBeZ4s52Tun_H4O")
        .then(
            (result) => {
            console.log(result.text)
            document.getElementById("buttonSubmit").setAttribute("value", "Envoyé !")
            document.getElementById("buttonSubmit").setAttribute("disabled", "true")
            },
            (error) => {
            console.log(error.text)
            alert("Je suis navrée, il semble qu'il y ait une erreur. Merci de réessayer plus tard, ou de me contacter à l'adresse e-mail suivante : contact@clairedevito.fr.")
            }
        )
    }

    return (
        <section animation={animation} id="contact" className={styles.section}>
            <div className={styles.section__title}>
                <h2>Contact <span>Contact</span></h2>
                <div className={styles.section__titleLine}></div>
            </div>
            <div className={styles.section__container}>
                <div className={styles.section__links}>
                    <a href="mailto: contact@clairedevito.fr">
                        <i className="fa-regular fa-envelope fa-2x"></i>
                        <span className="visually-hidden">M'envoyer un e-mail</span>
                    </a>
                    <a href="https://github.com/ClaireDV67" rel="noreferrer" target="_blank">
                        <i className="fa-brands fa-github fa-2x"></i>
                        <span className="visually-hidden">GitHub</span>
                    </a>
                    <a href="https://www.linkedin.com/in/claire-dv/" rel="noreferrer" target="_blank">
                        <i className="fa-brands fa-linkedin fa-2x"></i>
                        <span className="visually-hidden">Linkedin</span>
                    </a>
                    <a href="https://twitter.com/ClaireVito" rel="noreferrer" target="_blank">
                        <i className="fa-brands fa-twitter fa-2x"></i>
                        <span className="visually-hidden">Twitter</span>
                    </a>
                    <a href={cvDoc} download="ClaireDeVitoCV.pdf" title="CV" className={styles.iconePDF}>
                        <i className="fa-regular fa-file-pdf fa-2x"></i>
                        <span className="visually-hidden">CV</span>
                    </a>
                </div>
                <div className={styles.section__lineLinks}></div>
                <form ref={form} onSubmit={sendEmail} className={styles.section__form}>
                    <div>
                        <div>
                            <label htmlFor="form_name" className="visually-hidden">Nom</label>
                            <input id="form_name" className={styles.section__formInput} type="text" name="name" pattern='^[a-zA-Z\s"\-]{2,25}$' minLength="2" maxLength="25" placeholder="Votre nom" aria-required="true" required />
                            <label htmlFor="form_firstname" className="visually-hidden">Prénom</label>
                            <input id="form_firstname" className={styles.section__formInput} type="text" name="firstname" pattern='^[a-zA-Z\s"\-]{2,25}$' minLength="2" maxLength="25" placeholder="Votre prénom" />
                            <label htmlFor="form_email" className="visually-hidden">E-mail</label>
                            <input id="form_email" className={styles.section__formInput} type="email" name="email" pattern='^[\w]([\w\-\.])*@([\w\-\.])+\.([\w]){2,4}$' minLength="6" maxLength="50" placeholder="Votre e-mail" aria-required="true" required />
                        </div>
                        <div>
                            <label htmlFor="form_message" className="visually-hidden">Message</label>
                            <textarea id="form_message" name="message" minLength="10" maxLength="2000" placeholder="Votre message" aria-required="true" required></textarea>
                        </div>
                    </div>
                    <input className={styles.section__formSubmit} id="buttonSubmit" type="submit" value="Envoyer" />
                </form>
            </div>
        </section>
    )
}

export default Contact
