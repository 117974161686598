import { Card, ConfigProvider } from "antd"
import ProgressBar from "../../components/ProgressBar/ProgressBar"
import styles from "./Skills.module.scss"
import { useState } from "react"
import { useIsVisible } from "../../functions/useIsVisible"

const tabList = [
  {
    key: "tab1",
    tab: "Services",
  },
  {
    key: "tab2",
    tab: "Compétences",
  }
]

const contentList = {
  tab1: <ul className={styles.section__blocsList}>
            <li>Création de sites Web</li>
            <li>Sites responsives (compatibles ordinateurs, tablettes, et mobiles)</li>
            <li>Sites accessibles à tous</li>
            <li>Optimisation pour le référencement naturel</li>
            <li>Maintenance</li>
            <li>Débogage</li>
        </ul>,
  tab2: <ul className={styles.section__blocsListSkills}>
            <li>HTML5<ProgressBar percentage="80" /></li>
            <li>CSS3<ProgressBar percentage="80" /></li>
            <li>Sass<ProgressBar percentage="60" /></li>
            <li>JavaScript<ProgressBar percentage="60" /></li>
            <li>React<ProgressBar percentage="60" /></li>
            <li>NodeJS<ProgressBar percentage="40" /></li>
            <li>Express<ProgressBar percentage="40" /></li>
            <li></li>
        </ul>,
}

function Skills() {
    const [activeTabKey1, setActiveTabKey1] = useState("tab1")
    const onTab1Change = (key) => {
        setActiveTabKey1(key)
    }
    const animation = useIsVisible("skills")

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: "white",
                    colorBorderSecondary: "none",
                    colorPrimary: "black",
                    borderRadiusLG: 10,
                },
                components: {
                    Card: {
                        headerBg: "#A2B5A7"
                    },
                  }
            }}
        >
            <section id="skills" className={styles.section}>
                <div className={styles.angle}></div>
                <div animation={animation} className={styles.section__bloc}>
                    <h2>Activités <span>Services</span></h2>
                    <div className={styles.section__line}></div>
                    <div className={styles.section__blocs}>
                        <Card
                            style={{
                            width: "100%",
                            }}
                            tabList={tabList}
                            activeTabKey={activeTabKey1}
                            onTabChange={onTab1Change}
                        >
                            {contentList[activeTabKey1]}
                        </Card>
                    </div>
                </div>
            </section>
        </ConfigProvider>
    )
}

export default Skills
