import { useState } from "react"
import ReactModal from "react-modal"
import styles from "./Footer.module.scss"

function Footer() {
  // Ouverture et fermeture de la modale
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(true)
    document.getElementById("modalBtn").blur()
  }

  return (
    <footer role="contentinfo" className={styles.footer}>
        <p><i className="fa-regular fa-copyright"></i>Claire De Vito | 2023</p>
        <button
            id="modalBtn"
            onClick={toggleModal}
            aria-label="Ouverture des mentions légales"
            aria-haspopup
            aria-expanded={isOpen}
            className={styles.footer__button}
        >
            Politique de confidentialité
        </button>
        <ReactModal
        isOpen={isOpen}

        ariaHideApp={false}

        contentLabel={"Politique de confidentialité"}
        /* String indicating how the content container should be announced
          to screenreaders */

        onRequestClose={() => setIsOpen(false)}

        closeTimeoutMS={500}

        className={styles.footer__modal}
        /* String className to be applied to the modal content.
          See the `Styles` section for more details. */
        >
          <div className={styles.footer__modalContainer}>
            <button
                onClick={() => setIsOpen(false)}
                aria-label="Fermeture de la modale"
                className={styles.footer__modalCloseBtn}
            >
                <i className={styles.icone + " fa-solid fa-xmark fa-2xl"}></i>
            </button>
            <section aria-label="Politique de confidentialité" className={styles.footer__modalSection}>
              <h2>Politique de confidentialité</h2>
              <p>Cette politique de confidentialité décrit comment je collecte et utilise les données personnelles que vous me fournissez lorsque vous visitez mon site web.</p>
              <h3>Collecte et utilisation des données personnelles</h3>
              <p>Lorsque vous utilisez le formulaire de contact, je collecte les données personnelles suivantes : nom, prénom, et e-mail. Ces informations sont collectées dans le but de vous recontacter et de répondre à votre demande. J'utilise ces données uniquement à des fins de communication avec vous et ne les partage pas avec des tiers.</p>
              <h3>Conservation des données</h3>
              <p>Je ne conserve vos données personnelles que pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, sauf si une période de conservation plus longue est requise ou autorisée par la loi.</p>
              <h3>Vos droits</h3>
              <p>Vous avez le droit d'accéder à vos données personnelles, de les rectifier, de les supprimer, de limiter leur traitement et de vous opposer à leur traitement. Vous pouvez exercer ces droits en me contactant à l'adresse e-mail indiquée ci-dessous.</p>
              <h3>Contact</h3>
              <p>Si vous avez des questions concernant cette politique de confidentialité ou si vous souhaitez exercer vos droits concernant vos données personnelles, veuillez me contacter à l'adresse e-mail suivante : contact@clairedevito.fr.</p>
            </section>
          </div>
        </ReactModal>
    </footer>
  )
}

export default Footer