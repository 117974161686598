import Menu from "../../components/Menu/Menu"
import styles from "./Header.module.scss"
import avatar from "../../assets/images/avatar.webp"
import Background from "../../components/Background/Background"
import { useEffect, useState } from "react"

function Header() {
    const [visibleHeight, setVisibleHeight] = useState(0)

    // Fonction calculant la hauteur réelle de l'écran
    const getVisibleHeight = () => {
        let height = window.innerHeight + 1
        setVisibleHeight(height)
    }

    useEffect(() => {
        getVisibleHeight()

        window.addEventListener('resize', getVisibleHeight)

        return () => {
        window.removeEventListener('resize', getVisibleHeight)
        }
    }, [])

    return (
        <header className={styles.header} style={{ minHeight: visibleHeight }}>
            <Background />
            <Menu />
            <div className={styles.header__content}>
                <img src={avatar} alt="Avatar de Claire DE VITO" />
                <p>
                    Bonjour,<br />
                    <span className={styles.header__contentBig}>Je suis Claire De Vito,</span><br />
                    <span className={styles.header__contentBig}>Développeuse Web</span><br />
                    et passionnée
                </p>
            </div>
            <div className={styles.header__next}>
                <p>Découvrir la suite</p>
                <a href="#introduction">
                    <span className="visually-hidden">Accéder à la suite</span>
                    <i className={"fa-solid fa-angle-down fa-4x " + styles.header__nextArrow}></i>
                </a>
            </div>
        </header>
    )
}

export default Header
