import { useState } from "react"
import styles from "./Menu.module.scss"
import { useEffect } from "react"
import { throttle } from "lodash"

function Menu() {
    const [isOpen, setIsOpen] = useState(false) // État pour gérer l'ouverture/fermeture du menu
    const [animation, setAnimation] = useState(0) // État pour gérer l'animation
    const [scrolling, setScrolling] = useState(false)
    const [activeLink, setActiveLink] = useState("") // État pour suivre la section active

    // Fonction pour basculer le menu ouvert/fermé
    const toggleMenu = () => {
        if (isOpen === true) {
            if (animation === 0) {
                setAnimation(1)
            } else {
                setAnimation(0)
            }
            document.getElementById("burgerButton").blur()
            setTimeout(() => {setIsOpen(false)}, 450)
        } else {
            setIsOpen(true)
            if (animation === 0) {
                setAnimation(1)
            } else {
                setAnimation(0)
            }
        }
    }

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (window.scrollY > 0) {
                // L'utilisateur n'est pas tout en haut de la page
                setScrolling(true);
            } else {
                // L'utilisateur est tout en haut de la page
                setScrolling(false);
            }
        }, 200); // Limitez la fréquence à un appel toutes les 200 millisecondes

        // Écoute de l'événement de défilement de la page
        window.addEventListener("scroll", handleScroll);

        // Nettoyer l'écouteur d'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Fonction pour ajouter une classe à la section visible
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section")
            let currentSectionId = ""
    
            sections.forEach((section) => {
                const sectionTop = section.offsetTop
                const sectionBottom = sectionTop + section.offsetHeight
    
                if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
                    currentSectionId = section.id
                }
            })
    
            setActiveLink(currentSectionId)
        }

        window.addEventListener("scroll", handleScroll)
        
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    // Fonction pour faire défiler la page vers le haut de manière fluide
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Ajoute un effet de défilement fluide
        })
        document.getElementById("topButton").blur()
    }

    return (
        <div className={`${styles.menu} ${scrolling ? styles.scroll : ""}`}>
            <button aria-label="Haut de page" id="topButton" onClick={scrollTo} animation={animation}>
                <h1>Claire De Vito</h1>
            </button>
            <nav>
                <button
                    aria-label="Bouton Menu"
                    aria-controls="toggleNavbar"
                    aria-haspopup
                    aria-expanded={isOpen}
                    animation={animation}
                    onClick={toggleMenu}
                    className={styles.burger}
                    id="burgerButton"
                >
                    <div className={styles.burger__Line + " " + styles.burger__LineTop}></div>
                    <div className={styles.burger__Line + " " + styles.burger__LineMiddle}></div>
                    <div className={styles.burger__Line + " " + styles.burger__LineBottom}></div>
                    <span className="visually-hidden">Menu</span>
                </button>
                <ul
                    id="toggleNavbar"
                    animation={animation}
                    className={`${styles.menu__nav} ${isOpen ? "" : styles.hideMenu}`}
                >
                    <li>
                        <a
                            onClick={toggleMenu}
                            className={`${activeLink === "introduction" ? styles.active : ""}`}
                            href="#introduction"
                        >
                            <span className={styles.hideDesktop}>Présentation</span>
                            <span><i className={styles.icone__mob + " fa-solid fa-user fa-2x"}></i></span>
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={toggleMenu}
                            className={`${activeLink === "skills" ? styles.active : ""}`}
                            href="#skills"
                        >
                            <span className={styles.hideDesktop}>Activités</span>
                            <span><i className={styles.icone__mob + " fa-solid fa-screwdriver-wrench fa-2x"}></i></span>
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={toggleMenu}
                            className={`${activeLink === "portfolio" ? styles.active : ""}`}
                            href="#portfolio"
                        >
                            <span className={styles.hideDesktop}>Portfolio</span>
                            <span><i className={styles.icone__mob + " fa-solid fa-laptop-code fa-2x"}></i></span>
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={toggleMenu}
                            className={`${activeLink === "contact" ? styles.active : ""}`}
                            href="#contact"
                        >
                            <span className={styles.hideDesktop}>Contact</span>
                            <span><i className={styles.icone__mob + " fa-solid fa-envelope fa-2x"}></i></span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Menu